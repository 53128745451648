<template>
  <div @click.stop="close">
    <button
      type="button"
      class="btn btn-primary"
      id="button-open-dialog"
      data-bs-toggle="modal"
      data-bs-target="#kt_modal_1"
      hidden
    ></button>
    <div class="modal fade" tabindex="-1" id="kt_modal_1">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header d-flex justify-content-between">
            <h2 class="modal-title">เพิ่มรายการตัดสินค้าทั้งหมด</h2>
            <button
              data-bs-dismiss="modal"
              @click="close"
              type="button"
              class="btn btn-sm"
            >
              <i
                class="bi bi-x m-0 p-0"
                id="close-btn"
                style="font-size: 25px"
              ></i>
            </button>
          </div>

          <div class="modal-body">
            <!-- <div class="row d-flex justify-content-center mb-4">
              <label class="col-sm-9 text-start">เลขที่เอกสาร</label>
              <div class="col-sm-8">
                <input
                  disabled
                  type="text"
                  class="form-control"
                  id="inputGroupFile01"
                />
              </div>
            </div> -->
            <div class="row d-flex justify-content-center text-start mb-4">
              <label for="input1  form-label" class="col-sm-8"
                >ค้นหาสินค้า</label
              >
              <div class="col-sm-8">
                <input
                  v-model="searchProduct"
                  type="text"
                  class="form-control"
                  placeholder="ค้นหา Item code / ชื่อสินค้า
                      "
                  aria-describedby="basic-addon1"
                  :disabled="isView"
                  list="datalistOptions"
                />
                <datalist
                  id="datalistOptions"
                  v-if="searchListProduct.length >= 1"
                >
                  <option
                    id="datalistOptions11"
                    v-for="(item, index) in searchListProduct"
                    :key="index"
                    :value="item.code"
                  >
                    <hr style="color: LightGrey" class="mt-4 mb-0 pb-0" />
                  </option>
                </datalist>
              </div>
              <!-- <span v-if="searchListProduct[0]"
                >productId {{ searchListProduct[0].id }}
              </span> -->

              <!-- <div class="col-sm-4">
                <input
                  type="text"
                  class="form-control"
                  id="input1"
                  :style="
                    isSubmit
                      ? 'border-color: #ced4da;padding-right: 0.75rem;background: none;'
                      : ''
                  "
                />
              </div>

              <div class="col-sm-3 mb-3">
                <div
                  class="
                    form-check
                    form-check-inline
                    form-check-custom
                    form-check-solid
                    mt-3
                  "
                >
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    value="input1"
                    id="flexRadioChecked"
                  />
                  <label
                    class="form-check-label me-4"
                    for="flexRadioChecked"
                    :style="isSubmit ? 'color: black' : ''"
                    >Item no.</label
                  >
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    value="input2"
                    id="flexRadioChecked2"
                    checked="checked"
                  />
                  <label
                    class="form-check-label"
                    for="flexRadioChecked"
                    :style="isSubmit ? 'color: black' : ''"
                    >Barcode</label
                  >
                </div>
              </div>

              <div class="col-md-2">
                <button
                  type="button"
                  class="btn btn-sm btn-light-primary"
                  style="font-size: 14px; font-weight: bold; width: 100%"
                  width="100%"
                  @click="cancel"
                >
                  ค้นหา
                </button>
              </div> -->
            </div>

            <div
              class="row d-flex justify-content-center text-start mb-4"
              v-if="searchListProduct[0]"
            >
              <label class="col-sm-8">Item no</label>
              <div class="col-sm-8">
                <input
                  v-model="searchListProduct[0].code"
                  disabled
                  type="text"
                  class="form-control"
                  id="inputGroupFile01"
                />
              </div>
            </div>
            <div
              class="row d-flex justify-content-center text-start mb-4"
              v-if="searchListProduct[0]"
            >
              <label class="col-sm-8">Item Name</label>
              <div class="col-sm-8">
                <input
                  v-model="searchListProduct[0].name"
                  disabled
                  type="text"
                  class="form-control"
                  id="inputGroupFile01"
                />
              </div>
            </div>
            <!-- <div class="row d-flex justify-content-center text-start mb-4">
              <label class="col-sm-8">Barcode</label>
              <div class="col-sm-8">
                <input
                  disabled
                  type="text"
                  class="form-control"
                  id="inputGroupFile01"
                />
              </div>
            </div> -->

            <div
              class="row d-flex justify-content-center text-start"
              v-if="productstock && searchListProduct[0]"
            >
              <label class="col-sm-4">จำนวนหน่วย</label>
              <label class="col-sm-4 text-end"
                >จำนวนคงเหลือ :
                {{ productstock.stockAmt }}
                <!-- <b v-if="productstock.productUnit"
                  >{{ productstock.stockAmt }}
                  {{ productstock.productUnit.name }}
                </b> -->
              </label>
              <div class="col-sm-8">
                <input
                  v-model="requestStockAmt"
                  type="number"
                  min="0"
                  :max="productstock.stockAmt"
                  class="form-control"
                  id="inputGroupFile01"
                />
                <!-- oninput="javascript: if (this.value.length > productstock.stockAmt) this.value = this.value.slice(0, productstock.stockAmt);" -->
              </div>
            </div>
          </div>

          <div class="modal-footer d-flex justify-content-start">
            <hr class="pt-0 mt-0" style="color: LightGrey" />

            <footer>
              <div class="card-toolbar">
                <button
                  @click="submit()"
                  type="button"
                  class="btn btn-primary me-3"
                  data-bs-dismiss="modal"
                >
                  บันทึก
                </button>
                <button
                  @click="close"
                  type="button"
                  class="btn btn-light"
                  data-bs-dismiss="modal"
                >
                  ยกเลิก
                </button>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import whApi from "@/api/warehouse/";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
export default {
  props: {
    dialogAddItem: Boolean,
    VanId: String,
  },
  data: () => ({
    isSubmit: false,
    searchProduct: "",
    searchListProduct: [],
    productstock: [],
    stockAmt: "",
    requestStockAmt: "",
  }),
  watch: {
    searchProduct(val) {
      console.log("คำค้นหา", val);
      this.addProductItems(val);
    },
    dialogAddItem(val) {
      if (val) {
        document.getElementById("button-open-dialog").click();
        // this.getProductByvanId();
      }
    },
    searchListProduct(val) {
      if (val.length == 1) {
        this.getProductByvanId();
      }
    },
  },
  // productId {{ searchListProduct[0].id }}
  // warehouseId {{ warehouseId }}

  methods: {
    async addProductItems(searchProduct) {
      let responseData = [];
      try {
        responseData = await whApi.product.search({ search: searchProduct });
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.searchListProduct = responseData.data;
        console.log("this.searchListProduct", this.searchListProduct);
        // this.getProductByvanId();
      }
    },
    async getProductByvanId() {
      let responseData = [];
      try {
        responseData = await whApi.warehouse.getProductByvanId(
          this.VanId,
          this.searchListProduct[0].id
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.productstock = responseData.data[0];
      }
    },
    async submit() {
      let arr = this.searchListProduct[0];
      this.searchListProduct[0].productPrices.forEach((element) => {
        if (element.default == true) {
          arr.productPrices = element;
        }
      });
      let body = {
        productId: this.searchListProduct[0].id,
        code: this.searchListProduct[0].code,
        productUnitId: arr.productPrices.productUnit.id
          ? arr.productPrices.productUnit.id
          : 15,
          ///// productUnitId หน่วยสินค้า fixed id
        name: this.searchListProduct[0].name,
        fromWarehouseId: this.warehouseId,
        // toWarehouseId:
        requestStockAmt: this.requestStockAmt,
        unit: this.productstock.productUnit.name,
        // productTransfer:[],
        // remark:
        // transactionNumber:
      };
      this.$emit("addProduct", body);
      this.$emit("closeDialogAddItem");
    },
    close() {
      this.$emit("closeDialogAddItem");
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .display-phone {
    text-align: left !important;
  }
}
#close-btn:hover {
  background-color: LightGrey;
  border-radius: 25px;
  color: white;
}
</style>
